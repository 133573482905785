import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  tableCellClasses,
  Checkbox,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
  FormGroup,
} from "@mui/material";
import TableDataGrid from "components/ui/Tables/TableDataGrid";
import { styled } from "@mui/material/styles";
import SaveIcon from "@mui/icons-material/Save";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";

import { Formik } from "formik";
import * as Yup from "yup";

import moment from "moment";
import DateTimer from "components/DateTimer";

import testService from "services/test.services";
import {
  showAlertSuccess,
  showAlertError,
  showAlertWarning,
  showAlertInfo,
  showAlertConfirm,
} from "utils/alert";
import TextFieldCustom from "components/ui/TextFields/TextFieldCustom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableCustom = ({
  evaluacionesChecked,
  hcCheckMain,
  evaluaciones,
  setFechaEjecucion,
  handleClickIniciarPrueba,
  hcEvaluaciones,
  setValueTemp,
  fechaEjecucion,
  valueTemp,
  filterEncuestador,
}) => {
  const [filterTable, setFilterTable] = useState("");
  return (
    <>
      {/* evaluaciones */}
      <Grid item sm={12} md={12} sx={{ pt: "1em", pl: "3em" }}>
        <Typography variant="h6" align="left" color="text.primary">
          Listado de evaluaciones activas / Registros:{" "}
          {
            evaluaciones.filter(
              (item) =>
                (item.nombre.includes(filterTable) ||
                  item.cod_estudiante.includes(filterTable) ||
                  item.estudiante.toUpperCase().includes(filterTable)) &&
                item.visibleHabilitar === !filterEncuestador
            ).length
          }
        </Typography>
      </Grid>
      <Grid item sm={12} md={8} sx={{ padding: "1em" }}>
        <TextField
          fullWidth
          id="filterTable"
          name="filterTable"
          value={filterTable}
          onChange={(e) => setFilterTable(e.target.value.toUpperCase())}
          label="Filtrar por prueba, estudiante y/o código de estudiante"
          autoFocus
        />
      </Grid>
      <Grid item sm={12} md={12} sx={{ padding: "1em" }}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell width={"5%"} sx={{ pl: "15px" }}>
                  {/* <Checkbox color="secondary" /> */}
                  <Checkbox
                    id="mainCheck"
                    color="secondary"
                    checked={evaluacionesChecked}
                    onClick={(e) => hcCheckMain(e)}
                  />
                </StyledTableCell>
                <StyledTableCell width={"3%"}>#</StyledTableCell>
                <StyledTableCell width={"5%"}>Código</StyledTableCell>
                <StyledTableCell width={"15%"}>Prueba</StyledTableCell>
                <StyledTableCell width={"15%"}>
                  Código estudiante
                </StyledTableCell>
                <StyledTableCell width={"15%"}>Estudiante</StyledTableCell>
                <StyledTableCell width={"5%"}>Curso</StyledTableCell>
                <StyledTableCell width={"5%"}>Grupo</StyledTableCell>
                <StyledTableCell width={"5%"}>Estado</StyledTableCell>
                <StyledTableCell width={"15%"}>
                  Fecha Asignación
                </StyledTableCell>
                <StyledTableCell width={"15%"}>
                  Fecha Nacimiento
                </StyledTableCell>
                <StyledTableCell width={"2%"}>Iniciar</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {evaluaciones &&
                evaluaciones
                  .filter(
                    (item) =>
                      (item.nombre.includes(filterTable) ||
                        item.cod_estudiante.includes(filterTable) ||
                        item.estudiante.toUpperCase().includes(filterTable)) &&
                      item.visibleHabilitar === !filterEncuestador
                  )
                  .map((item, index) => (
                    <StyledTableRow key={item.evaluacion}>
                      <StyledTableCell component="th" scope="row">
                        <Checkbox
                          id={String(item.evaluacion)}
                          color="secondary"
                          checked={item.selected}
                          onClick={(e) => hcEvaluaciones(e, item)}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {item.evaluacion}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {item.nombre}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {item.cod_estudiante}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {item.estudiante}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {item.curso}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {item.grupo}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {item.estado}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {
                          <DateTimer
                            setValueTemp={setFechaEjecucion}
                            initialDate={moment(item.fecha_evaluacion).format(
                              "YYYY-MM-DD"
                            )}
                          />
                        }
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {item.fecha_nacimiento == null ? (
                          <DateTimer
                            setValueTemp={setValueTemp}
                            initialDate={
                              item.fecha_nacimiento !== null
                                ? moment(item.fecha_nacimiento).format(
                                    "YYYY-MM-DD"
                                  )
                                : null
                            }
                          />
                        ) : (
                          moment(item.fecha_nacimiento).format("YYYY-MM-DD")
                        )}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <Button
                          type="button"
                          fullWidth
                          variant="outlined"
                          disabled={item.estado !== "Activo" ? true : false}
                          startIcon={<PlayCircleFilledWhiteIcon />}
                          onClick={() => {
                            handleClickIniciarPrueba(
                              item.fecha_nacimiento,
                              fechaEjecucion,
                              item.evaluacion,
                              item.estudiante_id,
                              valueTemp
                            );
                          }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

const TableCustom2 = ({
  evaluaciones,
  handleClickIniciarPrueba,
  filterEncuestador,
  setEvaluacionesHabilitados,
  filterCode,
}) => {
  const headers = [
    {
      field: "evaluacion",
      headerName: "Eval",
      sortable: false,
      minWidth: 100,
      //hide: true,
      flex: 0.5,
    },
    {
      field: "nombre",
      headerName: "Nombre",
      minWidth: 300,
      flex: 3,
    },
    {
      field: "cod_estudiante",
      headerName: "Estudiante",
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: "estudiante",
      headerName: "Nombre Estudiante",
      minWidth: 200,
      flex: 2,
    },
    {
      field: "curso",
      headerName: "Curso",
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: "grupo",
      headerName: "Grupo",
      minWidth: 50,
      flex: 0.5,
    },
    {
      field: "estado",
      headerName: "Estado",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "fecha_evaluacion",
      headerName: "Fecha Evaluación",
      type: "date",
      flex: 1,
      minWidth: 100,
      editable: true,
      valueFormatter: (params) => {
        // Formatea la fecha en el formato deseado
        return moment(params.value).format("YYYY-MM-DD");
      },
    },
    {
      field: "fecha_nacimiento",
      headerName: "Fecha Nacimiento",
      type: "date",
      flex: 1,
      minWidth: 100,
      editable: true,
      valueFormatter: (params) => {
        // Formatea la fecha en el formato deseado
        return moment(params.value).format("YYYY-MM-DD");
      },
    },
  ];
  const [columns, setColumns] = useState(headers);
  const [filterTable, setFilterTable] = useState("");
  const [data, setData] = useState(evaluaciones);
  const setHeaders = () => {
    let array = [...columns];
    let iniciar = {
      field: "iniciar",
      headerName: "Iniciar",
      minWidth: 80,
      sortable: false,
      renderCell: (cellValues) => (
        <>
          <Button
            type="button"
            fullWidth
            variant="outlined"
            disabled={cellValues.row.estado !== "Activo" ? true : false}
            startIcon={<PlayCircleFilledWhiteIcon />}
            onClick={() => {
              handleClickIniciarPrueba(
                cellValues.row.fecha_nacimiento,
                cellValues.row.fecha_evaluacion,
                cellValues.row.evaluacion,
                cellValues.row.estudiante_id,
                null
              );
            }}
          />
        </>
      ),
      disableClickEventBubbling: true,
    };
    array.unshift(iniciar);    
    setColumns(array);
  };
  useEffect(() => {
    setHeaders();
  }, []);

  /*useEffect(() => {
    setData(
      evaluaciones.filter(
        (item) =>
          (item.nombre.includes(filterTable) ||
            item.cod_estudiante.includes(filterTable) ||
            item.estudiante.toUpperCase().includes(filterTable)) &&
          item.visibleHabilitar === !filterEncuestador
      )
    );
  }, [filterTable, filterEncuestador]);*/
  return (
    <>
      <>
        {/* evaluaciones */}
        <Grid item xs={12}>
          {/*<Typography variant="h6" align="left" color="text.primary">
            Listado de evaluaciones / Registros:{" "}
            {
              data.filter(
                (item) =>
                  (item.nombre.includes(filterTable) ||
                    item.cod_estudiante.includes(filterTable) ||
                    item.estudiante.toUpperCase().includes(filterTable)) &&
                  item.visibleHabilitar === !filterEncuestador
              ).length
            }
          </Typography>
          <Grid item sm={12} md={8} sx={{ padding: "1em" }}>
            <TextField
              fullWidth
              id="filterTable"
              name="filterTable"
              value={filterTable}
              onChange={(e) => setFilterTable(e.target.value.toUpperCase())}
              label="Filtrar por prueba, estudiante y/o código de estudiante"
              autoFocus
            />
          </Grid>*/}
        </Grid>
        {data.length > 0 && (
          <div /*style={{ height: 420, width: "100%", padding: "1em" }}*/>
            <TableDataGrid
              tittle={"Evaluaciones"}
              data={evaluaciones}
              columns={columns}
              selection={filterCode === "1" ? true : false}
              setData={setEvaluacionesHabilitados}
              filtersText={["nombre", "cod_estudiante", "estudiante"]}
              filterEncuestador={filterEncuestador}
            />
          </div>
        )}
      </>
    </>
  );
};

function InactiveSolveTestForm({ setOpenLoading }) {
  const [evaluaciones, setEvaluaciones] = useState([]);
  const [evaluacionesHabilitados, setEvaluacionesHabilitados] = useState([]);
  const [evaluacionesChecked, setEvaluacionesChecked] = useState(false);
  const [valueTemp, setValueTemp] = useState(null);
  const [fechaEjecucion, setFechaEjecucion] = useState(null);
  const [filterCode, setFilterCode] = useState("1");
  const [filterEncuestador, setFilterEncuestador] = useState(false);

  const navigate = useNavigate();

  async function getEvaluaciones(data) {
    try {
      setOpenLoading(true);
      var response = await testService.inactivarTest_GetEvaluaciones(data);
      if (response.status === 200) {
        setOpenLoading(false);
        let data = response.data;
        let arrayTemp = [];
        data.map((item) => {
          item.selected = false;
          item.id = item.evaluacion;
          arrayTemp.push(item);
        });
        arrayTemp.sort((a, b) => a.evaluacion - b.evaluacion);
        setEvaluaciones(arrayTemp);
      }
    } catch (error) {
      setOpenLoading(false);
      showAlertError("No se encontraron evaluaciones");
    }
  }
  async function inactivarPrueba(motivoInactivarPruebas) {
    try {
      setOpenLoading(true);
      let arr_evaluaciones = [];
      evaluacionesHabilitados.map((element) => {
        arr_evaluaciones.push({
          //evaluacion: element.evaluacion,
          evaluacion: element,
          mensaje: motivoInactivarPruebas,
        });
      });
      var response = await testService.inactivarTest_InactivarPrueba({
        evaluaciones: arr_evaluaciones,
      });
      if (response.status === 200) {
        setOpenLoading(false);
        showAlertSuccess("Evaluaciones inactivadas correctamente");
      }
    } catch (error) {
      setOpenLoading(false);
      showAlertError("No se pudieron asignar las pruebas!");
    }
  }

  async function actualizarFechaNacimiento(idEvaluacion, idEstudiante, fecha) {
    try {
      setOpenLoading(true);
      var response = testService.inactivarTest_ActualizarFechaNacimiento({
        estudiante: idEstudiante,
        fecha_nacimiento: moment(fecha).format("YYYY-MM-DD"),
      });
      if (response.status === 200) {
        setOpenLoading(false);
      }
    } catch (error) {
      setOpenLoading(false);
      showAlertError("No se pudieron asignar las pruebas!");
    }
  }

  const hcCheckMain = (e) => {
    let tempList = evaluaciones;
    tempList.map((item) => (item.selected = e.target.checked));
    setEvaluacionesHabilitados(evaluaciones.filter((e) => e.selected));
    setEvaluaciones(tempList);
    setEvaluacionesChecked(e.target.checked);
  };

  const hcEvaluaciones = (e, item) => {
    let tempList = evaluaciones;
    tempList.map((ele) => {
      if (ele.evaluacion === item.evaluacion) {
        ele.selected = e.target.checked;
      }
      return ele;
    });
    const totalItems = evaluaciones.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;
    setEvaluacionesHabilitados(evaluaciones.filter((e) => e.selected));
    setEvaluaciones(tempList);
    setEvaluacionesChecked(totalItems === totalCheckedItems);
  };

  const handleClickIniciarPrueba = (
    fechaNac,
    fechaEjecucion,
    idEvaluacion,
    idEstudiante,
    nuevaFechaNac
  ) => {
    if (!fechaNac) {
      if (!nuevaFechaNac) {
        showAlertWarning(
          "No puede iniciar la prueba, el estudiante no tiene fecha de nacimiento!"
        );
      } else {
        actualizarFechaNacimiento(
          idEvaluacion,
          idEstudiante,
          nuevaFechaNac
        ).then(() => {
          navigate(
            "/dashboard/test" +
              "/" +
              idEvaluacion +
              "/" +
              moment(fechaEjecucion).format("YYYY-MM-DD")
          );
        });
      }
    } else {
      navigate(
        "/dashboard/test" +
          "/" +
          idEvaluacion +
          "/" +
          moment(fechaEjecucion).format("YYYY-MM-DD")
      );
    }
  };

  const handleClickFilter = (event, resetForm) => {
    setFilterCode(event.target.value);
    resetForm();
    clearForm();
  };

  const initForm = () => {
    //getEvaluaciones({ finalizadas: 1 },);
    getEvaluaciones({ finalizadas: filterCode });
  };

  const formSchema = Yup.object().shape({
    motivo: Yup.string().required("Campo Requerido"),
  });

  const clearForm = () => {
    setEvaluaciones([]);
    setEvaluacionesHabilitados([]);
    setEvaluacionesChecked(false);
  };

  useEffect(() => {
    clearForm();
    initForm();
  }, [filterCode]);

  useEffect(() => {
    initForm();
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          motivo: "",
        }}
        validationSchema={formSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (evaluacionesHabilitados.length === 0) {
            showAlertInfo("Debe seleccionar las evaluaciones a inactivar!");
          } else {
            showAlertConfirm(
              "warning",
              "Total de pruebas a inactivar " + evaluacionesHabilitados.length,
              "¿Desea inactivar las pruebas?",
              async function (confirmed) {
                if (confirmed) {
                  await inactivarPrueba(values.motivo).then(() => {
                    clearForm();
                    initForm();
                    resetForm();
                  });
                }
              }
            );
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          /* and other goodies */
        }) => (
          <Box
            component="form"
            noValidate
            id='document.getElementById("asignar-pruebas-form").reset();'
            sx={{ mt: 1 }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                bgcolor: "background.paper",
                borderRadius: 1,
              }}
            >
              {/* Radio */}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={3}
                sx={{ padding: "1em 1em 1em 1em" }}
              >
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Estados pruebas
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={filterCode}
                    onChange={(event) => handleClickFilter(event, resetForm)}
                  >
                    <FormControlLabel
                      value={"1"}
                      control={<Radio />}
                      label="Activas"
                    />
                    <FormControlLabel
                      value={"0"}
                      control={<Radio />}
                      label="Finalizadas"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* Check Mostar pruebas encuestador */}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={2}
                sx={{ padding: "2em 1em 1em 1em" }}
              >
                <FormGroup>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={filterEncuestador}
                        onChange={(e) => {
                          setFilterEncuestador(!filterEncuestador);
                        }}
                      />
                    }
                    label="Filtrar encuestador"
                  />
                </FormGroup>
              </Grid>
              <Grid item sm={12} md={5} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="motivo"
                  label="Motivo de inactivación de pruebas"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={String(touched.motivo)}
                  value={values.motivo || ""}
                  error={errors.motivo}
                />
              </Grid>
              <Grid item sm={12} md={2} sx={{ padding: "1em" }}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "none", height: "3.5em" }}
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Inactivar
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
      {/* Table Custom */}
      {/*<TableCustom
        evaluacionesChecked={evaluacionesChecked}
        hcCheckMain={hcCheckMain}
        evaluaciones={evaluaciones}
        setFechaEjecucion={setFechaEjecucion}
        handleClickIniciarPrueba={handleClickIniciarPrueba}
        hcEvaluaciones={hcEvaluaciones}
        setValueTemp={setValueTemp}
        fechaEjecucion={fechaEjecucion}
        valueTemp={valueTemp}
        filterEncuestador={filterEncuestador}
      />*/}
      {evaluaciones.length > 0 && (
        <Grid item xs={12} sx={{ padding: "1em" }}>
          <TableCustom2
            evaluaciones={evaluaciones}
            handleClickIniciarPrueba={handleClickIniciarPrueba}
            filterEncuestador={filterEncuestador}
            setEvaluacionesHabilitados={setEvaluacionesHabilitados}
            filterCode={filterCode}
          />
        </Grid>
      )}
    </>
  );
}

export default InactiveSolveTestForm;
