import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const ButtonTest = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  borderRadius: "0.5em",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    color: "#000000",
    boxShadow: "0 0 0 0.1rem " + theme.palette.primary.main,
  },
  "&:active": {
    backgroundColor: theme.palette.primary.dark,
  },
  "&:focus": {
    //boxShadow: '0 0 0 0.2rem ' + theme.palette.primary.main,
  },
}));

const ButtonCustom = (props) => {
  return (
    <ButtonTest
      variant={props.variant != null ? props.variant : "contained"}
      {...props}
    />
  );
};

export default ButtonCustom;
