import React, { useRef, useEffect, useState } from "react";
import { config } from "utils/config";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Chip
} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import "styles/PizarraInteractiva.css";
import ButtonCustom from "components/ui/Buttons/ButtonCustom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PizarraModal = (props) => {
  const { timeStartPrueba,
    tiempoLimite,
    seconds,
    pregunta,
    ancho,
    alto,
    opcion,
    updateRespuestasSeleccionadas,
    updateSeguimiento,
    recurso } = props;
  const [mostrarModal, setMostrarModal] = useState(false);
  const [canceled, setCanceled] = useState(true);
  const handleClose = () => {
    setCanceled(false);
    setMostrarModal(false);
  };
  const handleCancel = () => {
    setCanceled(true);
    setMostrarModal(false);
  };
  return (
    <>
      {!mostrarModal ? (
        <>
          <br />
          <Button
            onClick={e => { setMostrarModal(true) }}
            variant="contained"
            color="info"
          >
            Iniciar dibujo en la Pizarra
          </Button>
          {!canceled && (<Chip color="success" label="Dibujo completado" icon={<DoneIcon />} sx={{ ml: "1em" }} />)}
        </>
      ) : (
        <>
          <Dialog
            open={mostrarModal}
            TransitionComponent={Transition}
            keepMounted
            fullScreen
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
            maxWidth="lg"
          >
            <DialogContent>
              <Pizarra timeStartPrueba={timeStartPrueba}
                tiempoLimit={tiempoLimite}
                seconds={seconds}
                pregunta={pregunta}
                ancho={ancho}
                alto={alto}
                opcion={opcion}
                updateRespuestasSeleccionadas={updateRespuestasSeleccionadas}
                updateSeguimiento={updateSeguimiento}
                recurso={recurso} />
            </DialogContent>
            <DialogActions>
              <ButtonCustom
                variant="outlined"
                color="primary"
                onClick={handleCancel}>
                Cancelar
              </ButtonCustom>
              <Button
                onClick={handleClose}
                variant="contained">
                Terminar dibujo
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}


    </>
  );
};

function Pizarra(props) {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [lastX, setLastX] = useState(0);
  const [lastY, setLastY] = useState(0);
  const [imagenFondo, setImagenFondo] = useState(null);
  const [contextG, setContextG] = useState(null);

  const [historyDraw, setHistoryDraw] = useState([]);
  const [flagBorrar, setFlagBorrar] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    setContextG(ctx);

    const handleMouseDown = (e) => {
      setIsDrawing(true);
      setLastX(e.clientX - canvas.offsetLeft);
      setLastY(e.clientY - canvas.offsetTop);
    };

    const handleMouseMove = (e) => {
      if (!isDrawing) return;
      const mouseX = e.clientX - canvas.offsetLeft;
      const mouseY = e.clientY - canvas.offsetTop;
      drawLine(ctx, lastX, lastY, mouseX, mouseY);
      setLastX(mouseX);
      setLastY(mouseY);
    };

    const handleMouseUp = () => {
      setIsDrawing(false);
      saveCanvaImage();
    };

    const handleTouchStart = (e) => {
      e.preventDefault();
      const touch = e.touches[0];
      const mouseX = touch.clientX - canvas.offsetLeft;
      const mouseY = touch.clientY - canvas.offsetTop;
      setLastX(mouseX);
      setLastY(mouseY);
      setIsDrawing(true);
    };

    const handleTouchMove = (e) => {
      if (!isDrawing) return;
      e.preventDefault();
      const touch = e.touches[0];
      const mouseX = touch.clientX - canvas.offsetLeft;
      const mouseY = touch.clientY - canvas.offsetTop;
      drawLine(ctx, lastX, lastY, mouseX, mouseY);
      setLastX(mouseX);
      setLastY(mouseY);
    };

    const handleTouchEnd = () => {
      setIsDrawing(false);
      saveCanvaImage();
    };

    const saveCanvaImage = async () => {
      try {
        const base64Canvas = canvas.toDataURL();

        var arrTemp = historyDraw;
        if (arrTemp.length === 0) {
          arrTemp = [];
        }
        arrTemp.push(base64Canvas)
        setHistoryDraw(arrTemp)
        setFlagBorrar(false);

        //guardar respuesta
        let tiempoRespuesta = props.tiempoLimite - props.seconds;
        if (props.tiempoLimite < 0) {
          tiempoRespuesta = parseInt(
            (Date.now() - props.timeStartPrueba) / 1000
          );
        }
        let pregunta = props.pregunta;
        let respuesta = props.opcion;
        let textoInputRespuesta = base64Canvas;
        props.updateRespuestasSeleccionadas(
          parseInt(pregunta),
          parseInt(respuesta),
          parseInt(tiempoRespuesta),
          null,
          textoInputRespuesta
        );
        props.updateSeguimiento(
          "Respuesta seleccionada",
          `Pregunta:${pregunta};Respuesta:${respuesta};TiempoRespuesta:${tiempoRespuesta};RespuestaAutomatica:${textoInputRespuesta}`
        );
      } catch (error) {
        console.log("Error al guardar la imagen...", error);
      }
    };
    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("touchstart", handleTouchStart);
    canvas.addEventListener("touchmove", handleTouchMove);
    canvas.addEventListener("touchend", handleTouchEnd);

    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseup", handleMouseUp);
      canvas.removeEventListener("touchstart", handleTouchStart);
      canvas.removeEventListener("touchmove", handleTouchMove);
      canvas.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isDrawing, lastX, lastY]);

  useEffect(() => {
    if (!imagenFondo) return;
    contextG.drawImage(imagenFondo, 0, 0, props.ancho ?? 600, props.alto ?? 500);
  }, [imagenFondo]);

  useEffect(() => {
    asignarImagen();
  }, []);

  const asignarImagen = () => {
    let server = config.API_RESOURCE_URL;
    let IMAGE_DEFAULT = server + props.recurso || "";
    var img = new Image();
    img.src = IMAGE_DEFAULT;
    // img.crossOrigin = 'Anonymous';
    img.onload = () => {
      setImagenFondo(img);
    };
  };

  const borrar = () => {
    var lastDraw = historyDraw.pop();

    if (lastDraw) {
      var imageLastDraw = new Image();
      imageLastDraw.src = lastDraw

      imageLastDraw.addEventListener("load", (e) => {
        contextG.clearRect(0, 0, window.innerWidth - 100, window.innerWidth - 100);
        contextG.drawImage(imageLastDraw, 0, 0, props.ancho ?? 600, props.alto ?? 500);
      });
      setFlagBorrar(true);
    } else {
      setHistoryDraw([]);
      contextG.clearRect(0, 0, window.innerWidth - 100, window.innerWidth - 100);
      asignarImagen();
    }
  };

  const borrarTodo = () => {
    setHistoryDraw([]);
    contextG.clearRect(0, 0, window.innerWidth - 100, window.innerWidth - 100);
    asignarImagen();
    setFlagBorrar(false);
  };

  const drawLine = (ctx, x1, y1, x2, y2) => {
    ctx.strokeStyle = "#000";
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
    ctx.closePath();
  };

  return (
    <div align="center">
      <br />
      <div style={{ paddingBottom: "10px" }}>
        <button onClick={borrar} style={{ marginRight: "10px" }}>Borrar</button>
        <button onClick={borrarTodo}>Borrar todo</button>
      </div>
      <div>
        <canvas
          id={"canva-image" + props.opcion}
          ref={canvasRef}
          width={props.ancho ?? 600}
          height={props.alto ?? 500}
        />
      </div>
    </div>
  );
}

export default PizarraModal;
