import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  CardMedia,
  Slide,
  Box,
  Stepper,
  Step,
  StepLabel
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import {
  showAlertSuccess,
  showAlertError,
  showAlertConfirm,
  showAlertInput,
  showAlertWarning,
} from "utils/alert";
import Instruccion from "../../components/Instruccion";
import IntervaloTiempo from "../../components/IntervaloTiempo";
import Loading from "components/Loading";
import { config } from "utils/config";
import testService from "services/test.services";
import DetailTest from "../../components/DetailTest";
import CargarRecursoInicial from "./CargarRecursoInicial";
import AppBar from "./AppBar";
import { useSelector, useDispatch } from "react-redux";
import { resetState, asignarPruebas } from "libs/redux/slices/testSlice";
import ButtonCustom from "components/ui/Buttons/ButtonCustom";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'auto'
  });
};

const tipoEncuesta = config.TIPO_ENCUESTA;
const alertaEncuestaPendiente = config.ALERTA_ENCUESTA_PENDIENTE_RESPONDER;
const alertaEncuestaNumero = config.ALERTA_ENCUESTA_FORMAT_NUMERO;
const SolveTest = () => {
  const [respPrueba, setRespPrueba] = useState([]);
  const [seconds, setSeconds] = useState();
  const [timeStartPrueba, setTimeStartPrueba] = useState();
  const [openLoading, setOpenLoading] = useState(false);
  const [tiempoLimite, setTiempoLimite] = useState(0);
  const [steps, setSteps] = useState([]);

  const [suspensionPorErrores, setSuspensionPorErrores] = useState(-1);
  const [cantidadIntentos, setCantidadIntentos] = useState(1);

  const [secondsQuestion, setSecondsQuestion] = useState();
  const [starQuestion, setStarQuestion] = useState();
  const { idPrueba, fechaEvaluacion } = useParams();
  const navigate = useNavigate();

  const { respuestas } = useSelector((state) => state.test);
  const dispatch = useDispatch();

  // Consumo endpoint inicio prueba
  async function startPrueba() {
    try {
      await testService.startTest(idPrueba, fechaEvaluacion);
    } catch (error) {
      let data_error = error.response.data;
      showAlertError(data_error.mensaje);
      navigate("/dashboard");
    }
  }
  // Consumo endpoint finalizar prueba
  async function endPrueba(messageFinPrueba = true, value) {
    try {
      const response = await testService.endTest(
        idPrueba,
        respuestas
      );
      if (response.status === 200) {
        dispatch(resetState());
        if (messageFinPrueba) {
          setStatePruebaFinalizada(value);
          showAlertSuccess("Prueba finalizada con éxito");
        }
        navigate("/dashboard");
      }
    } catch (error) {
      let data_error = error.response.data;
      showAlertError(data_error.mensaje);
      navigate("/dashboard");
    }
  }
  async function cancelTest(motivoCancelacion) {
    try {
      dispatch(resetState());
      const response = await testService.cancelTest(
        idPrueba,
        motivoCancelacion
      );
      if (response.status === 200) {
        showAlertSuccess("Prueba cancelada con éxito");
        navigate("/dashboard");
      }
    } catch (error) {
      let data_error = error.response.data;
      showAlertError(data_error.mensaje);
      navigate("/dashboard");
    }
  }
  async function getPrueba() {
    try {
      setOpenLoading(true);
      let response = await testService.getTestDetail(idPrueba);
      if (response.status === 200) {
        setOpenLoading(false);
        dispatch(resetState());
        setRespPrueba(response.data.data);
        setRespuestasPendientes(response.data.data.preguntas.length);
        setSeconds(response.data.data.tiempoLimite);
        setTiempoLimite(response.data.data.tiempoLimite);

        if (response.data.data.otros.suspensionPorErrores) {
          setSuspensionPorErrores(response.data.data.otros.suspensionPorErrores);
        }

        let preguntas = [];
        response.data.data.preguntas.forEach((item, index) => {
          preguntas.push(index + 1);
        });
        setSteps(preguntas);
      }
    } catch (error) {
      setOpenLoading(false);
      showAlertError("Ha ocurrido un error al obtener la prueba");
      navigate("/dashboard");
    }
  }
  async function verificarRespuestaCapturada(data) {
    try {
      const response = await testService.validarRespuestaTest(data);
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
    }
  }
  useEffect(() => {
    getPrueba();
  }, []);

  // Track prueba
  const [seguimiento, setSeguimiento] = useState({});
  const [mostrarRI, setMostrarRI] = useState(); // Validar si existe recurso inicial
  const checkRecursoInicial = (recursoInicial) => {
    recursoInicial ? setMostrarRI(true) : setMostrarRI(false);
  };
  async function updateSeguimiento(mensaje, data = null) {
    let now = Date.now();
    let tempSeguimiento = seguimiento;
    let index = Object.keys(tempSeguimiento).length;
    tempSeguimiento[`${index}-${now}`] = {
      mensaje,
      data,
    };
    setSeguimiento(tempSeguimiento);
    let history_data = {
      evaluacion: idPrueba,
      data: {
        data: data,
        mensaje: mensaje,
        timestamp: now,
      },
    };
    await testService.assingTestHistory(history_data);
  }

  // Control de respuestas seleccionadas
  const [respuestasSeleccionadas, setRespuestasSeleccionadas] = useState([]);
  const [alertasControlRespuestas, setAlertasControlRespuestas] = useState({});
  const [gruposRespuestas, setGruposRespuestas] = useState([]);

  const updateRespuestasSeleccionadas = async (
    pregunta,
    respuesta,
    tiempoRespuesta,
    textoInputRespuesta = null,
    file = null,
    multipleRespuesta,
    respuestaEliminar,
    radio,
    grupoItem
  ) => {
    try {
      let tempRespuestasSeleccionadas = [...respuestas];
      if (!isNaN(pregunta) && !isNaN(respuesta)) {
        let dataValorCapt = {};
        dataValorCapt["intentos"] = cantidadIntentos;
        dataValorCapt["grupoItem"] = grupoItem;
        dataValorCapt["tiempoRespuesta"] = tiempoRespuesta;
        dataValorCapt["respuesta"] = {};
        if (textoInputRespuesta !== null) {
          dataValorCapt["respuesta"]["automatica"] = textoInputRespuesta;
        }
        if (file !== null) {
          dataValorCapt["respuesta"]["file"] = file;
        }

        // let preguntaConRespuesta = false;
        // try {
        //   tempRespuestasSeleccionadas.forEach((item) => {
        //     if (item.pregunta === pregunta && !multipleRespuesta) {
        //       preguntaConRespuesta = true;
        //       item.respuesta = respuesta;
        //       item.data = dataValorCapt;
        //     }
        //   });
        // } catch (error) {
        //   // console.log(error, "tempoRespuestas");
        // }
        if (!multipleRespuesta) {
          // if (!preguntaConRespuesta && !multipleRespuesta) {
          // tempRespuestasSeleccionadas.push({
          //   evaluacion: idPrueba,
          //   pregunta: pregunta,
          //   respuesta: respuesta,
          //   data: dataValorCapt,
          // });
          let existe = tempRespuestasSeleccionadas.some((item) => item.pregunta === pregunta);
          if (existe) {
            tempRespuestasSeleccionadas = tempRespuestasSeleccionadas.map(item =>
              item.pregunta === pregunta
                ? { ...item, data: dataValorCapt }
                : item
            );
          } else {
            tempRespuestasSeleccionadas.push({
              evaluacion: idPrueba,
              pregunta: pregunta,
              respuesta: respuesta,
              data: dataValorCapt,
            });
          }
        }
        if (multipleRespuesta) {
          if (respuestaEliminar) {
            const indiceAEliminar = tempRespuestasSeleccionadas.findIndex(function (
              ele
            ) {
              return (
                ele.pregunta === pregunta && ele.respuesta === respuestaEliminar
              );
            });
            if (indiceAEliminar > -1) {
              tempRespuestasSeleccionadas.splice(indiceAEliminar, 1);
            }
          } else if (
            !tempRespuestasSeleccionadas.find(
              (ele) => ele.pregunta === pregunta && ele.respuesta === respuesta
            )
          ) {
            tempRespuestasSeleccionadas.push({
              evaluacion: idPrueba,
              pregunta: pregunta,
              respuesta: respuesta,
              data: dataValorCapt,
            });
          }
        }
        let tempAlertasControlRespuestas = alertasControlRespuestas;
        tempAlertasControlRespuestas[pregunta] = 0;
        setAlertasControlRespuestas(tempAlertasControlRespuestas);
      }
      // setRespuestasSeleccionadas(tempRespuestasSeleccionadas);      
      const response = await updateControlRespuestasPreguntas(
        pregunta,
        respuesta,
        tiempoRespuesta,
        multipleRespuesta,
        tempRespuestasSeleccionadas,
        radio
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const showAlertaPreguntasSinRespuesta = (respPrueba) => {
    let tempAlertasControlRespuestas = alertasControlRespuestas;
    respPrueba.preguntas.map((pregunta, index) => {
      if (!(pregunta.codPregunta in tempAlertasControlRespuestas)) {
        tempAlertasControlRespuestas[pregunta.codPregunta] =
          alertaEncuestaPendiente;
      }
    });
    setAlertasControlRespuestas(tempAlertasControlRespuestas);
  };
  // Control de cantidad respuestas seleccionadas
  const [controlRespuestasPreguntas, setControlRespuestasPreguntas] =
    useState(0);
  const [respuestasPendientes, setRespuestasPendientes] =
    useState(0);
  const updateControlRespuestasPreguntas = async (
    pregunta,
    respuesta,
    tiempoRespuesta,
    multpleRespuesta,
    respuestasSeleccionadas,
    radio
  ) => {
    let cantidadPreguntasPendiente = null;
    if (!multpleRespuesta) {
      cantidadPreguntasPendiente = respuestasSeleccionadas.length;//`Respuestas pendientes: ${respuestasSeleccionadas.length}/${respPrueba.preguntas.length}`;
    } else {
      const uniqueNames = new Set(
        respuestasSeleccionadas.map((item) => item.pregunta)
      );
      const totalRespuestas = uniqueNames.size;
      cantidadPreguntasPendiente = totalRespuestas;//`Respuestas pendientes: ${totalRespuestas}/${respPrueba.preguntas.length}`;
    }
    await updateSeguimiento(
      "Cantidad de preguntas por responder",
      respuestasPendientes - cantidadPreguntasPendiente
    );
    if (!radio) {
      dispatch(asignarPruebas({
        respuestas: respuestasSeleccionadas,
        respuestasSeleccionadas: cantidadPreguntasPendiente
      }));
    } else {
      return { respuestas: respuestasSeleccionadas, respuestasSeleccionadas: cantidadPreguntasPendiente }
    }
  };

  // Control de inicio de prueba
  const [stateInicioPrueba, setStateInicioPrueba] = useState(false);
  const updateInicioPrueba = () => {
    showAlertConfirm(
      "warning",
      "¿Fueron claras las instrucciones?",
      "Si fueron claras, por favor presione el botón SI para iniciar la prueba, de lo contrario presione NO",
      async function (confirmed) {
        if (confirmed) {
          scrollToTop();
          setTimeStartPrueba(Date.now());
          checkRecursoInicial(respPrueba.recursoInicial);
          setStateInicioPrueba(true);
          startPrueba();
          updateSeguimiento("Inicio de prueba");
          // window.scrollTo(0, 0);
          if (respPrueba.otros.tiempoPregunta) {
            setStarQuestion(Date.now());
            setSecondsQuestion(respPrueba.otros.tiempoPregunta);
          }
        }
      }
    );
  };

  // Control de fin de tiempo de realización de prueba
  const [statePruebaFinalizada, setStatePruebaFinalizada] = useState(false);
  const [cerrarComponentes, setCerrarComponentes] = useState(false);
  const updatePruebaFinalizada = (value, messageFinPrueba = true) => {
    setCerrarComponentes(true);
    endPrueba(messageFinPrueba, value);
  };

  // Control de mensaje de intento de fin de prueba
  const [stateMensajeIntentoFinPrueba, setStateMensajeIntentoFinPrueba] =
    useState("");
  const updateMensajeIntentoFinPrueba = (value) => {
    setStateMensajeIntentoFinPrueba(value);
  };

  // Control de update de radio input
  const handleRadioChange = async (event) => {
    let tiempoRespuesta = respPrueba.tiempoLimite - seconds;
    if (respPrueba.tiempoLimite < 0) {
      tiempoRespuesta = parseInt((Date.now() - timeStartPrueba) / 1000);
    }
    let pregunta = event.target.name;
    let respuesta = event.target.value;

    if ((pregunta !== null || pregunta !== undefined) ||
      (respuesta !== null || respuesta !== undefined)) {
      const response = await updateRespuestasSeleccionadas(
        parseInt(pregunta),
        parseInt(respuesta),
        parseInt(tiempoRespuesta),
        null,
        null,
        null,
        null,
        true,
      );
      await updateSeguimiento(
        "Respuesta seleccionada",
        `Pregunta:${pregunta};Respuesta:${respuesta};TiempoRespuesta:${tiempoRespuesta}`
      );
      return response;
    } else {
      return null;
    }
  };

  const handleTextAreaChange = (param, grupo_item) => (event) => {
    let pregunta = event.target.name;
    let respuesta = event.target.id;
    let textoInputRespuesta = event.target.value;
    let grupoItem = "";
    if (grupo_item) grupoItem = grupo_item;

    let tiempoRespuesta = respPrueba.tiempoLimite - seconds;
    if (respPrueba.tiempoLimite < 0) {
      tiempoRespuesta = parseInt((Date.now() - timeStartPrueba) / 1000);
    }
    // if (param === "input_numerico") {
    //   const numericValue = Number(textoInputRespuesta);
    //   if (Number.isNaN(numericValue) || numericValue < 0) {
    //     let tempRespuestasSeleccionadas = respuestasSeleccionadas;
    //     let nuevoRespuestasSeleccionadas = [];

    //     tempRespuestasSeleccionadas.forEach((item) => {
    //       if (item.pregunta !== pregunta) {
    //         nuevoRespuestasSeleccionadas.push(item);
    //       }
    //     });

    //     setRespuestasSeleccionadas(nuevoRespuestasSeleccionadas);
    //     updateControlRespuestasPreguntas(
    //       pregunta,
    //       respuesta,
    //       tiempoRespuesta,
    //       false,
    //       tempRespuestasSeleccionadas
    //     );

    //     let tempAlertasControlRespuestas = alertasControlRespuestas;
    //     tempAlertasControlRespuestas[pregunta] = alertaEncuestaNumero;
    //     setAlertasControlRespuestas(tempAlertasControlRespuestas);
    //     return;
    //   }
    // }
    updateRespuestasSeleccionadas(
      parseInt(pregunta),
      parseInt(respuesta),
      parseInt(tiempoRespuesta),
      textoInputRespuesta,
      null,
      null,
      null,
      null,
      grupoItem
    );
    updateSeguimiento(
      "Respuesta seleccionada",
      `Pregunta:${pregunta};Respuesta:${respuesta};TiempoRespuesta:${tiempoRespuesta};RespuestaAutomatica:${textoInputRespuesta}`
    );
  };

  const secondChance = async () => {
    var currentItem = respPrueba.preguntas[activeStep];
    if (currentItem.otros.intentosRespuestaCorrecta && respuestas.length > 0) {
      var intentos = currentItem.otros.intentosRespuestaCorrecta;

      let itemRespuestas = [];
      respuestas.forEach((item, index) => {
        if (currentItem.codPregunta === item.pregunta) {
          itemRespuestas.push({ "respuesta": item.respuesta, "valor": item.data.respuesta.automatica });
        }
      });

      var isError = await verificarRespuestaCapturada({
        "tipo": 1, "prueba": respPrueba.prueba, "pregunta": currentItem.codPregunta, "respuestas": itemRespuestas
      })

      if (!isError && intentos - cantidadIntentos > 0) {
        var tmpCantidadIntentos = cantidadIntentos
        setCantidadIntentos(tmpCantidadIntentos + 1)
        showAlertWarning("Tienes una segunda oportunidad, vuelve a intentarlo")
        return false;
      }
    }
    return true;
  }

  const testFinalizado = (props) => {
    if (props.seconds === 0) {
      if (respPrueba.obligaRespuesta) {
        if (
          (parseInt(respuestas.length, 0) <
            parseInt(respPrueba.preguntas.length, 0) &&
            tiempoLimite < 0 &&
            respPrueba.tipo !== tipoEncuesta) ||
          (parseInt(respuestas.length, 0) <
            parseInt(respPrueba.preguntas.length, 0) &&
            tiempoLimite > 0 &&
            props.seconds > 0 &&
            respPrueba.tipo !== tipoEncuesta) ||
          respPrueba.tipo === tipoEncuesta
        ) {
          let preguntasPendientesPorResponder =
            respPrueba.preguntas.length - respuestas.length;
          let complementoMensaje = `Faltan ${preguntasPendientesPorResponder} respuetas de ${respPrueba.preguntas.length} preguntas.`;
          updateMensajeIntentoFinPrueba(complementoMensaje);
          showAlertaPreguntasSinRespuesta(respPrueba);
          updateSeguimiento(
            "Intento de finalización de prueba por usuario",
            complementoMensaje
          );
        } else {
          updateSeguimiento("Prueba finalizada por usuario");
          updatePruebaFinalizada(true);
        }
      } else {
        updateSeguimiento("Prueba finalizada por usuario");
        updatePruebaFinalizada(true);
      }
    } else {
      // Valida si está en el último step
      if (activeStep === steps.length - 1) {
        secondChance();
      }

      showAlertConfirm(
        "warning",
        "Mensaje de confirmación de finalización de prueba",
        "¿Deseas terminar la actividad?",
        async function (confirmed) {
          if (confirmed) {
            if (respPrueba.obligaRespuesta) {
              if (
                (parseInt(respuestas.length, 0) <
                  parseInt(respPrueba.preguntas.length, 0) &&
                  tiempoLimite < 0 &&
                  respPrueba.tipo !== tipoEncuesta) ||
                (parseInt(respuestas.length, 0) <
                  parseInt(respPrueba.preguntas.length, 0) &&
                  tiempoLimite > 0 &&
                  props.seconds > 0 &&
                  respPrueba.tipo !== tipoEncuesta) ||
                respPrueba.tipo === tipoEncuesta
              ) {
                let preguntasPendientesPorResponder =
                  respPrueba.preguntas.length - respuestas.length;
                let complementoMensaje = `Faltan ${preguntasPendientesPorResponder} respuetas de ${respPrueba.preguntas.length} preguntas.`;
                updateMensajeIntentoFinPrueba(complementoMensaje);
                showAlertaPreguntasSinRespuesta(respPrueba);
                updateSeguimiento(
                  "Intento de finalización de prueba por usuario",
                  complementoMensaje
                );
              } else {
                updateSeguimiento("Prueba finalizada por usuario");
                updatePruebaFinalizada(true);
              }
            } else {
              updateSeguimiento("Prueba finalizada por usuario");
              updatePruebaFinalizada(true);
            }
          }
        }
      );
    }
  };

  const cancelarTest = () => {
    showAlertConfirm(
      "warning",
      "¿Desea cancelar la prueba?",
      "La cancelación de prueba se realiza cuando el evaluado no está apto para realizarla.",
      async function (confirmed) {
        if (confirmed) {
          showAlertInput(
            "Digite el motivo de cancelación",
            "¡Debe digitar el motivo de cancelación!",
            async function (result) {
              if (result.isConfirmed) {
                await cancelTest(result.value);
              }
            }
          );
        }
      }
    );
  };

  //Steps
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    // Validación errores
    var errores = 0;
    let sumErrores = false;
    let tipoSuspencion = respPrueba.otros.tipo_suspencion;
    let cantidadRespuestasItem = respPrueba.otros.cantidad_respuestas;
    let grupos = [...gruposRespuestas];
    if (suspensionPorErrores > -1) {

      var preguntasRespuesta = [];
      respuestas.forEach((item, index) => {
        preguntasRespuesta.push(item.pregunta);
      });

      // let itemRespuestas = [];
      let isOk = false;
      for (const pregunta of preguntasRespuesta) {
        switch (tipoSuspencion) {
          //Suspención por grupos
          case 1:
            let itemRespuestas = [];
            // for (let i = 0; i < respuestas.length; i++) {
            // const item = respuestas[i];
            const item = respuestas.find(item => item.pregunta === pregunta);
            const grupo = item.data.grupoItem;
            // respuestas.forEach((item, index) => {
            //   if (item.pregunta === pregunta) {
            //     itemRespuestas.push({ "respuesta": item.respuesta, "valor": item.data.respuesta.automatica });
            //   }
            // });
            itemRespuestas.push({ "respuesta": item.respuesta, "valor": item.data.respuesta.automatica });
            let isError = await verificarRespuestaCapturada({
              "tipo": 2, "prueba": respPrueba.prueba, "pregunta": pregunta, "respuestas": itemRespuestas
            })
            let existe = grupos.some((item) => item.grupo === grupo);
            if (existe) {
              // for (let item of grupos) {                
              //   const existePregunta = item.preguntas.includes(pregunta);
              //   if (item.grupo === grupo && !existePregunta) {
              //     let valor = !isError ? 0 : 1;
              //     let arrayPreguntas = [...item.preguntas]
              //     arrayPreguntas.push(pregunta);

              //     item.valor = valor;
              //     item.cantidadRespuestas = item.cantidadRespuestas + 1;
              //     item.preguntas = arrayPreguntas;
              //     break;
              //   }
              // }
              grupos = grupos.map(item => {
                if (item.grupo === grupo && !item.preguntas.includes(pregunta)) {
                  const valor = !isError ? 0 : 1;
                  const valorAciertos = item.valor + valor;
                  const cont = item.cantidadRespuestas + 1;
                  let arrayPreguntas = [...item.preguntas]
                  arrayPreguntas.push(pregunta);
                  const jsonResult = { ...item, valor: valorAciertos, cantidadRespuestas: cont, preguntas: arrayPreguntas }
                  return jsonResult;
                }
                return item;
              });
            } else {
              let valor = !isError ? 0 : 1;
              let arrayPreguntas = []
              arrayPreguntas.push(pregunta);
              let json = { grupo, valor, cantidadRespuestas: 1, preguntas: [...arrayPreguntas] }
              grupos.push(json);
            }
            // }     
            sumErrores = grupos.some((item) => item.cantidadRespuestas === cantidadRespuestasItem && item.valor === 0);
            setGruposRespuestas(grupos);
            break;
          case 2:
            respuestas.forEach((item, index) => {
              if (pregunta === item.pregunta) {
                itemRespuestas.push({ "respuesta": item.respuesta, "valor": item.data.respuesta.automatica });
              }
            });

            let isOk = await verificarRespuestaCapturada({
              "tipo": 2, "prueba": respPrueba.prueba, "pregunta": pregunta, "respuestas": itemRespuestas
            })
            sumErrores = !isOk;
            break;
          default:
            break;
        }


        if (sumErrores) {
          errores++;
        }
        console.log(errores, suspensionPorErrores)
        if (errores >= suspensionPorErrores) {
          updateSeguimiento("Prueba finalizada por sistema, se ha alcanzado el número de errores máximo permitido");
          updatePruebaFinalizada(true, false);
          showAlertSuccess("Ha alcanzado el número de errores máximo permitido. La prueba ha sido finalizada.");
          return;
        }
      }
    }

    var continueSecondChance = await secondChance();
    if (!continueSecondChance) {
      return;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    if (secondsQuestion) {
      setStarQuestion(Date.now());
      setSecondsQuestion(respPrueba.otros.tiempoPregunta);
    }
    setCantidadIntentos(1) // Reinicia el número de intentos permitidos por prueba
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const siguienteMultiplo = (actual) => {
    let multiplo = respPrueba.otros.salto;
    let resultado = 0;
    for (let i = actual; i < respPrueba.preguntas.length - 1; i++) {
      if (i % multiplo === 0 && i !== 1) {
        resultado = i;
        break;
      }
    }
    return resultado;
  };

  const handleSkip = () => {
    /*if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }*/
    let saltarA = siguienteMultiplo(activeStep + 1);
    //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setActiveStep(saltarA);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <Loading openLoading={openLoading} />
      <AppBar
        respPrueba={respPrueba}
        stateInicioPrueba={stateInicioPrueba}
        statePruebaFinalizada={statePruebaFinalizada}
        respuestasPendientes={respuestasPendientes}
        stateMensajeIntentoFinPrueba={stateMensajeIntentoFinPrueba}
        mostrarRI={mostrarRI}
        testFinalizado={testFinalizado}
        seconds={seconds}
        setSeconds={setSeconds}
        timeStartPrueba={timeStartPrueba}
      />
      <Grid
        container
        component="main"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          bgcolor: "background.paper",
          borderRadius: 1,
          mb: "6em",
          mt: "4em"
        }}
      >
        {stateInicioPrueba === false && statePruebaFinalizada === false && (
          <>
            <Grid item sm={12} md={12} sx={{ padding: "1em" /*, mt: "10em"*/ }}>
              <Instruccion instruccion={respPrueba.instruccion} />
            </Grid>
          </>
        )}
        <Box sx={{ width: "100%", mt: "4em" }}>
          {stateInicioPrueba === true && statePruebaFinalizada === false ? (
            mostrarRI ? (
              <Grid item sm={12} md={12} sx={{ padding: "1em" }}>
                <CargarRecursoInicial
                  mostrarRI={mostrarRI}
                  setMostrarRI={setMostrarRI}
                  recurso={respPrueba.recursoInicial.recurso}
                  tipo={respPrueba.recursoInicial.tipo}
                  repeticion={respPrueba.recursoInicial.repeticiones}
                  setTimeStartPrueba={setTimeStartPrueba}
                />
              </Grid>
            ) : respPrueba.mostrar11 === true ? (
              <>
                {/*<Box sx={{ width: "100%", mt: "8em" }}>*/}
                <Grid item xs={12}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      if (isStepSkipped(index)) {
                        stepProps.completed = false;
                      }
                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{/*label*/}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Grid>
                {activeStep === steps.length ? (
                  <>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      Terminaste todas las respuestas
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </>
                ) : (
                  //contenido
                  <>
                    <DetailTest
                      respPrueba={respPrueba}
                      alertasControlRespuestas={alertasControlRespuestas}
                      handleRadioChange={handleRadioChange}
                      handleTextAreaChange={handleTextAreaChange}
                      timeStartPrueba={timeStartPrueba}
                      seconds={seconds}
                      updateRespuestasSeleccionadas={
                        updateRespuestasSeleccionadas
                      }
                      updateSeguimiento={updateSeguimiento}
                      activeStep={activeStep}
                      filter={true}
                      secondsQuestion={secondsQuestion}
                      setSecondsQuestion={setSecondsQuestion}
                      starQuestion={starQuestion}
                      handleNext={handleNext}
                      testFinalizado={testFinalizado}
                      lastQuestion={
                        activeStep === steps.length - 1 ? true : false
                      }
                      cerrarComponentes={cerrarComponentes}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "space-around",
                        pl: "3em",
                        pb: "1em",
                      }}
                    >
                      {respPrueba.otros.salto &&
                        activeStep <
                        steps.length - respPrueba.otros.salto - 1 && (
                          <Button
                            variant="text"
                            //color="inherit"
                            onClick={handleSkip}
                            sx={{ ml: 1 }}
                          >
                            {respPrueba.otros.textoBtnPasar
                              ? respPrueba.otros.textoBtnPasar
                              : "Pasar ejercicio"}
                          </Button>
                        )}
                      <>
                        <ButtonCustom
                          variant="outlined"
                          color="primary"
                          disabled={activeStep === steps.length - 1}
                          onClick={handleNext}
                        >
                          {respPrueba.otros.textoBtnSiguiente
                            ? respPrueba.otros.textoBtnSiguiente
                            : "Siguiente"}
                        </ButtonCustom>
                      </>
                    </Box>
                  </>
                )}
                {/*</Box>*/}
              </>
            ) : (
              <DetailTest
                respPrueba={respPrueba}
                alertasControlRespuestas={alertasControlRespuestas}
                handleRadioChange={handleRadioChange}
                handleTextAreaChange={handleTextAreaChange}
                timeStartPrueba={timeStartPrueba}
                seconds={seconds}
                updateRespuestasSeleccionadas={updateRespuestasSeleccionadas}
                updateSeguimiento={updateSeguimiento}
                activeStep={activeStep}
                filter={false}
                cerrarComponentes={cerrarComponentes}
              />
            )
          ) : null}
        </Box>
      </Grid>
      <div      >
        {stateInicioPrueba === false && statePruebaFinalizada === false && (
          <>
            <div style={{
              backgroundColor: "#E7E7E7",
              borderTop: "1px solid #E7E7E7",
              textAlign: "center",
              display: "flex",
              padding: "20px",
              height: "30px",
              width: "100%",
              position: "fixed",
              justifyContent: "center",
              alignItems: "center",
              bottom: "0",
            }}>
              <Grid item sm={12} md={2} sx={{ padding: "1em" }}>
                <Button
                  onClick={updateInicioPrueba}
                  variant="contained"
                  sx={{ padding: "0.5em" }}
                >
                  Iniciar prueba
                </Button>
              </Grid>
              <Grid item sm={12} md={2} sx={{ padding: "0.5em" }}>
                <Button onClick={cancelarTest} variant="text">
                  Cancelar Prueba
                </Button>
              </Grid>
            </div>

          </>
        )}
        {stateInicioPrueba && !statePruebaFinalizada && (
          <>
            <div style={{
              backgroundColor: "#E7E7E7",
              borderTop: "1px solid #E7E7E7",
              textAlign: "center",
              display: "flex",
              padding: "20px",
              height: "30px",
              width: "100%",
              position: "fixed",
              justifyContent: "flex-end",
              alignItems: "center",
              bottom: "0",
            }}>
              <Grid item sm={12} md={2}>
                <Button
                  onClick={testFinalizado}
                  variant="contained"
                  sx={{ marginRight: "100px" }}
                >
                  Finalizar prueba
                </Button>
              </Grid>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SolveTest;