import React, { useState, useEffect } from "react";
import {
  Grid,
  FormHelperText,
  InputLabel,
  FormControl,
  Box,
  MenuItem,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import SaveIcon from "@mui/icons-material/Save";
import { Formik } from "formik";
import * as Yup from "yup";
import SelectCustom from "components/ui/Selects/SelectCustom";
import testService from "services/test.services";
import paramsService from "services/params.services";
import { showAlertSuccess, showAlertError, showAlertInfo } from "utils/alert";
import TableStudents from "./TableStudents";
import TableChecked from "components/ui/Tables/TableChecked";
import SelectAutocomplete from "components/ui/Selects/SelectAutocomplete";
import TextFieldCustom from "components/ui/TextFields/TextFieldCustom";
import { useNavigate } from "react-router-dom";

const initialValues = {
  colegio: "",
  grado: "",
  grupo: "",
  tipoPrueba: "",
  prueba: "",
  codEstudiante: "",
};

const formSchema = Yup.object().shape({
  colegio: Yup.string(),
  grado: Yup.string(),
  grupo: Yup.string(),
  codEstudiante: Yup.string(),
  tipoPrueba: Yup.string().required("Campo Requerido"),
  prueba: Yup.string(),
});

const AssingTestForm = ({ setOpenLoading }) => {
  const [colegios, setColegios] = useState([]);
  const [grados, setGrados] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [tipoPruebas, setTipoPruebas] = useState([]);
  const [pruebas, setPruebas] = useState([]);
  const [estudiantes, setEstudiantes] = useState([]);
  const [edadEstudiante, setEdadEstudiante] = useState("");
  const [filterCode, setFilterCode] = useState("filterCode");
  const [asignarComponente, setAsignarComponente] = useState(false);
  const [pruebasAsignar, setPruebasAsignar] = useState([]);
  const navigate = useNavigate();
  let submitAction;
  const theme = useTheme();
  async function getColegios() {
    try {
      var response = await paramsService.getSchools();
      if (response.status === 200) {
        setColegios(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getGrados(data) {
    try {
      var response = await paramsService.getGrades(data);
      if (response.status === 200) {
        setGrados(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getGrupos(data) {
    try {
      var response = await paramsService.getClassRoom(data);
      if (response.status === 200) {
        setGrupos(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getTipoPruebas() {
    try {
      setOpenLoading(true);
      var response = await testService.getTestType({ ocultar: 1 });
      if (response.status === 200) {
        setTipoPruebas(response.data.data);
        setOpenLoading(false);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
      setOpenLoading(false);
    }
  }
  const getPruebas = async (tipoPrueba) => {
    try {
      setPruebas(tipoPruebas.find((e) => e.key === tipoPrueba).pruebas);
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  };
  const asignarTipoPruebas = async (tipoPrueba) => {
    let pruebas = tipoPruebas.find((e) => e.key === tipoPrueba).pruebas;
    pruebas.map((item) => (item.selected = false));
    setPruebasAsignar(pruebas);
  };
  async function getEstudiantes(data) {
    try {
      setOpenLoading(true);
      let codEstudiante = data.codEstudiante;
      var response = await paramsService.getStudents(data);
      if (response.status === 200) {
        setOpenLoading(false);
        let data = response.data.data;
        setEstudiantes([]);
        let arrayTemp = [];
        // eslint-disable-next-line array-callback-return
        data.map((item) => {
          if (filterCode === "filterCode") {
            if (item.identificador === codEstudiante) {
              item.selected = false;
              arrayTemp.push(item);
              if (item.edad) setEdadEstudiante({ edad: item.edad });
            }
          } else {
            item.selected = false;
            arrayTemp.push(item);
          }
        });
        if (arrayTemp.length > 0) {
          setEstudiantes(arrayTemp);
        } else {
          showAlertInfo("No se encontraron estudiantes para asignar");
        }
        return arrayTemp;
      }
    } catch (error) {
      setOpenLoading(false);
      showAlertError("No se encontraron estudiantes");
    }
  }
  async function asignarPrueba(data) {
    try {
      setOpenLoading(true);
      var response = await testService.assingTest(data);
      if (response.status === 200) {
        setOpenLoading(false);
      }
    } catch (error) {
      setOpenLoading(false);
      showAlertError("No se pudieron asignar las pruebas!");
    }
  }
  const handleClickFilter = (event, resetForm) => {
    setFilterCode(event.target.value);
    clearForm();
    resetForm();
    setAsignarComponente(false);
  };
  const handleAsignarComponente = (event, resetForm) => {
    clearForm();
    resetForm();
  };
  const initForm = () => {
    getColegios();
    getTipoPruebas();
  };
  const clearForm = () => {
    setColegios([]);
    setGrados([]);
    setGrupos([]);
    setTipoPruebas([]);
    setPruebas([]);
    setEstudiantes([]);
    setPruebasAsignar([]);
    setEdadEstudiante("");
    initForm();
  };
  useEffect(() => {
    initForm();
  }, []);
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        validate={(values) => {
          const errors = {};
          if (filterCode === "filterMany") {
            if (values.colegio) {
              let data = {
                colegio: values.colegio,
              };
              getGrados(data);
            }
            if (values.grado) {
              let data = {
                colegio: values.colegio,
                grado: values.grado,
              };
              getGrupos(data);
            }
          }
          if (!asignarComponente && !values.prueba) {
            errors.prueba = "Campo Requerido";
          }
          if (filterCode === "filterCode" && !values.codEstudiante) {
            errors.codEstudiante = "Campo Requerido";
          } else if (filterCode === "filterMany") {
            if (!values.colegio) {
              errors.colegio = "Campo Requerido";
            }
            if (!values.grado) {
              errors.grado = "Campo Requerido";
            }
            if (!values.grupo) {
              errors.grupo = "Campo Requerido";
            }
          }
          //console.log(errors.colegio);
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          //Swal.showLoading(null);
          if (submitAction === "buscar") {
            if (filterCode === "filterCode") {
              asignarTipoPruebas(values.tipoPrueba);
              //si asignarComponente se obtiene la info del estudiante
              getEstudiantes({
                prueba: asignarComponente ? 0 : values.prueba,
                codEstudiante: values.codEstudiante,
              });
            } else {
              getEstudiantes({
                colegio: values.colegio,
                grado: values.grado,
                curso: values.grupo,
                prueba: values.prueba,
              });
            }
          } else if (submitAction === "habilitar") {
            let data = [];
            if (!asignarComponente) {
              if (estudiantes.filter((item) => item.selected).length === 0) {
                showAlertInfo("Debe seleccionar los estudiantes!");
              } else {
                estudiantes
                  .filter((item) => item.selected)
                  .map((item) => data.push(item.id));
                asignarPrueba({
                  prueba: values.prueba,
                  estudiantes: data,
                }).then(() => {
                  showAlertSuccess("Pruebas asignadas correctamente");
                  clearForm();
                  resetForm();
                  navigate("/dashboard/inactive-test", { replace: true });
                });
              }
            } else {
              getEstudiantes({
                prueba: 0,
                codEstudiante: values.codEstudiante,
              }).then((dataResult) => {
                if (dataResult.length === 0) {
                  showAlertInfo("No se encontró el estudiante!");
                  return;
                }
                if (
                  pruebasAsignar.filter((item) => item.selected).length === 0
                ) {
                  showAlertInfo("Debe seleccionar las pruebas asignar!");
                  return;
                }
                dataResult.map((item) => data.push(item.id));
                pruebasAsignar
                  .filter((item) => item.selected)
                  .forEach(async (item) => {
                    await asignarPrueba({
                      prueba: item.key,
                      estudiantes: data,
                    });
                  });
                showAlertSuccess("Pruebas asignadas correctamente");
                clearForm();
                resetForm();
                navigate("/dashboard/inactive-test", { replace: true });
              });
            }
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Box
            component="form"
            noValidate
            id="asignar-pruebas-form"
            sx={{ mt: 1 }}
          >
            {/* Toolbar */}
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                //background: theme.palette.secondary.main,
                background: theme.palette.secondary.light,
                padding: "0,5em",
                minHeight: "3em",
                //pt: "3em"
              }}
            >
              {/* Habilitar */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "none", height: "3em" }}
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    submitAction = "habilitar";
                    handleSubmit();
                  }}
                >
                  Asignar
                </Button>
              </Grid>
              {/* Limpiar */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <Button
                  type="button"
                  fullWidth
                  variant="outlined"
                  sx={{ textTransform: "none", height: "3em" }}
                  startIcon={<CleaningServicesIcon />}
                  onClick={() => {
                    clearForm();
                    resetForm();
                  }}
                >
                  Limpiar
                </Button>
              </Grid>
              {/* Buscar */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "none", height: "3em" }}
                  startIcon={<SearchTwoToneIcon />}
                  onClick={() => {
                    submitAction = "buscar";
                    handleSubmit();
                  }}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
            {/* Form */}
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                bgcolor: "background.paper",
                borderRadius: 1,
                padding: "1em",
              }}
            >
              {/* Radio */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={3}
                sx={{ padding: "1em 1em 1em 1em" }}
              >
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Asignación...
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={filterCode}
                    onChange={(event) => handleClickFilter(event, resetForm)}
                  >
                    <FormControlLabel
                      value="filterCode"
                      control={<Radio />}
                      label="Por código"
                    />
                    <FormControlLabel
                      value="filterMany"
                      control={<Radio />}
                      label="Multiple"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* Check Asignar Componente */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={2}
                sx={{ padding: "2em 1em 1em 1em" }}
              >
                <FormGroup>
                  <FormControlLabel
                    required
                    disabled={filterCode !== "filterCode"}
                    control={
                      <Checkbox
                        checked={asignarComponente}
                        onChange={(e) => {
                          setAsignarComponente(!asignarComponente);
                          handleAsignarComponente(e, resetForm);
                        }}
                      />
                    }
                    label="Asignar componente"
                  />
                </FormGroup>
              </Grid>
              {/* Tipo Pruebas */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <FormControl fullWidth error={errors.tipoPrueba ? true : false}>
                  <InputLabel id="input-label-inst">Tipos Pruebas*</InputLabel>
                  <SelectCustom
                    labelId="select-label-prueba"
                    id="select-tipo-prueba"
                    name="tipoPrueba"
                    label="tipos pruebas*"
                    onChange={(e) => {
                      getPruebas(e.target.value);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    value={values.tipoPrueba}
                  >
                    {tipoPruebas &&
                      tipoPruebas.map((item) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                  </SelectCustom>
                  {errors.tipoPrueba && touched.tipoPrueba && (
                    <FormHelperText>{errors.tipoPrueba}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {!asignarComponente && (
                <>
                  {/* Pruebas */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={5}
                    sx={{ padding: "1em" }}
                  >
                    <SelectAutocomplete
                      name="prueba"
                      label="Pruebas*"
                      onOpen={handleBlur}
                      setFieldValue={setFieldValue}
                      options={pruebas}
                      error={errors.prueba}
                      touched={String(touched.prueba)}
                      id="key"
                      desc="value"
                    />
                    {/*<Autocomplete
                  disablePortal
                  id="select-prueba"
                  onChange={(e, value) =>
                    setFieldValue("prueba", value != null ? value.key : "")
                  }
                  onOpen={handleBlur}
                  options={pruebas}
                  getOptionLabel={(option) =>
                    option.value ? option.value : ""
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.key === value.key
                  }
                  //defaultValue={savedData != null ? savedData.typeDocument_id : null}//{{ name: "NIT", id: "62c7a68497f9a1c40231507f" }}
                  //loading={isLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.prueba && errors.prueba)}
                      fullWidth
                      helperText={touched.prueba && errors.prueba}
                      label="Pruebas*"
                      name="prueba"
                      variant="outlined"
                    />
                  )}
                />*/}
                  </Grid>
                </>
              )}
              {filterCode === "filterCode" ? (
                <>
                  {/* CodEstudiante */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={5}
                    sx={{ padding: "1em" }}
                  >
                    <TextFieldCustom
                      name="codEstudiante"
                      label="Código Estudiante"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={String(touched.codEstudiante)}
                      value={values.codEstudiante || ""}
                      error={errors.codEstudiante}
                    />
                  </Grid>
                  {edadEstudiante.edad && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      sx={{
                        padding: "2em 1em 1em 1em",
                        justifyContent: "left",
                      }}
                    >
                      <Typography variant="subtitle1" gutterBottom>
                        Edad: {edadEstudiante.edad.años} años,{" "}
                        {edadEstudiante.edad.meses} meses,{" "}
                        {edadEstudiante.edad.dias} días
                      </Typography>
                    </Grid>
                  )}
                </>
              ) : filterCode === "filterMany" ? (
                <>
                  {/* Colegios */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={5}
                    sx={{ padding: "1em" }}
                  >
                    <SelectAutocomplete
                      name="colegio"
                      label="Colegios*"
                      onOpen={getColegios}
                      setFieldValue={setFieldValue}
                      options={colegios}
                      error={errors.colegio}
                      touched={String(touched.colegio)}
                      id="id"
                      desc="nombre"
                    />
                  </Grid>
                  {/* Grados */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={2}
                    sx={{ padding: "1em" }}
                  >
                    <FormControl fullWidth error={errors.grado ? true : false}>
                      <InputLabel id="input-label-grado">Grados*</InputLabel>
                      <SelectCustom
                        labelId="select-label-grado"
                        id="select-grado"
                        name="grado"
                        label="grados*"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.grado}
                      >
                        {grados &&
                          grados.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.nombre}
                            </MenuItem>
                          ))}
                      </SelectCustom>
                      {errors.grado && touched.grado && (
                        <FormHelperText>{errors.grado}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* Grupos */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={2}
                    sx={{ padding: "1em" }}
                  >
                    <FormControl fullWidth error={errors.grupo ? true : false}>
                      <InputLabel id="input-label-inst">Grupos*</InputLabel>
                      <SelectCustom
                        labelId="select-label-grupo"
                        id="select-grupo"
                        name="grupo"
                        label="grupos*"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.grupo}
                      >
                        {grupos &&
                          grupos.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.nombre}
                            </MenuItem>
                          ))}
                      </SelectCustom>
                      {errors.grupo && touched.grupo && (
                        <FormHelperText>{errors.grupo}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Box>
        )}
      </Formik>
      {!asignarComponente ? (
        <TableStudents
          estudiantes={estudiantes}
          setEstudiantes={setEstudiantes}
        />
      ) : (
        //Tabla Pruebas
        <TableChecked
          name={"Pruebas a asignar"}
          data={pruebasAsignar}
          setData={setPruebasAsignar}
          id="key"
          headers={[
            { porcentaje: "35%", nombre: "Codigo" },
            { porcentaje: "60%", nombre: "Nombre" },
          ]}
          keys={["key", "value"]}
        />
      )}
    </>
  );
};

export default AssingTestForm;
